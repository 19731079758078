import { useMemo } from "react";
import styled from "styled-components";
import { useTableElements } from "@sablier/v2-contexts";
import { useWindowSize } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import nextDynamic from "next/dynamic";
import Blocks from "../Blocks";

/**
 * IMPORTANT NOTE 📒
 *
 * Make sure the first ancestor of the Table that has explicit "relative" positioning is the Page itself.
 * https://github.com/sablier-labs/v2-interfaces/discussions/612
 */

const ContainerStatic = nextDynamic(
  () => import("./Container").then((module) => module.ContainerStatic),
  { ssr: false },
);
const ContainerVirtualized = nextDynamic(
  () => import("./Container").then((module) => module.ContainerVirtualized),
  { ssr: false },
);

const theme = getTheme();

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
    border-radius: 0 0 8px 8px;
    background-color: ${(props) => props.theme.colors.dark150};
    overflow: hidden;
  }
`;

function Body() {
  const { isLoading, isEmpty, options, rows } = useTableElements();

  const { width } = useWindowSize();

  const isMobile = useMemo(
    () => width < parseInt(theme.sizes.deviceLGBreak, 10),
    [width],
  );

  return (
    <Wrapper data-component={"body"}>
      {!isEmpty ? (
        <>
          {isMobile ? (
            <ContainerStatic rows={rows} />
          ) : (
            <ContainerVirtualized
              isLoading={!!isLoading}
              options={options}
              rows={rows}
            />
          )}
        </>
      ) : (
        false
      )}
      {isLoading ? <Blocks.Loading /> : false}
      {isEmpty ? (
        <Blocks.Empty option={options.empty} error={options.error} />
      ) : (
        false
      )}
    </Wrapper>
  );
}

export default Body;
