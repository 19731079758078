import { memo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Button, Tooltip } from "~/components/molecules";
import type { CSSProperties, ComponentProps } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    padding: 8px 16px;
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark100};
  }
`;

const Group = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: 8px;
    & > *,
    & > *[data-mini="true"] {
      justify-content: flex-start;
      min-width: 140px;
      padding-inline: 0;
      p {
        font-weight: 600 !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: 6px;
      padding-bottom: 6px;
      border-bottom: 2px solid ${(props) => props.theme.colors.border};
    }
  }
`;

export interface Props extends ComponentProps<typeof Button> {
  isMenu?: boolean;
  menu?: {
    group?: string;
    items: Omit<
      ComponentProps<typeof Button>,
      "accent" | "appearance" | "isMini"
    >[];
  }[];
}

const Menu = memo(function Menu({
  data,
}: {
  data: NonNullable<Props["menu"]>;
}) {
  return (
    <Wrapper>
      {data.map(({ group, items }) => (
        <Group key={group}>
          {items.map((item, index) => (
            <Button
              key={index}
              {...item}
              accent={"tableMenu"}
              appearance={"transparent"}
              isMini
            />
          ))}
        </Group>
      ))}
    </Wrapper>
  );
});

const overrides: CSSProperties = {
  padding: "0",
  borderRadius: "0",
};

function Action({ isMenu, menu, ...props }: Props) {
  if (!isMenu || !menu?.length) {
    return <Button {...props} />;
  }
  return (
    <Tooltip
      arrow={false}
      color={"transparent"}
      placement={"left"}
      trigger={"click"}
      container={overrides}
      value={<Menu data={menu} />}
    >
      <div>
        <Button {...props} />
      </div>
    </Tooltip>
  );
}

export default Action;
