import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Amount, Label } from "~/components/molecules";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: center;
    height: 100%;
  }
`;

export interface Props {
  data: ITableCell & {
    value: {
      label: ComponentProps<typeof Label> | string;
      amount: ComponentProps<typeof Amount>;
    };
  };
}

function AmountDeckCell({ data }: Props) {
  const value = useMemo(() => data.value, [data]);
  const amount = useMemo(() => value.amount || {}, [value]);

  const Top = useCallback(() => {
    const label = value.label;
    if (_.isString(label)) {
      return <Label value={label} />;
    }
    if (_.has(label, "value")) {
      return <Label {...label} />;
    }
    return <></>;
  }, [value]);

  return (
    <Wrapper>
      <Inner>
        <Top />
        <Amount {...amount} />
      </Inner>
    </Wrapper>
  );
}

export default AmountDeckCell;
