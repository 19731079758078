import type { ComponentProps } from "react";
import Actions from "./Actions";
import AddressDeck from "./AddressDeck";
import Amount from "./Amount";
import AmountDeck from "./AmountDeck";
import Label from "./Label";
import Progress from "./Progress";
import Text from "./Text";
import TextDeck from "./TextDeck";
import TextDeckSelect from "./TextDeckSelect";

const Cells = {
  AddressDeck,
  Amount,
  AmountDeck,
  Actions,
  Label,
  Progress,
  Text,
  TextDeck,
  TextDeckSelect,
};

export type CellType = {
  AddressDeck: ComponentProps<typeof AddressDeck>["data"];
  Amount: ComponentProps<typeof Amount>["data"];
  AmountDeck: ComponentProps<typeof AmountDeck>["data"];
  Actions: ComponentProps<typeof Actions>["data"];
  Label: ComponentProps<typeof Label>["data"];
  Progress: ComponentProps<typeof Progress>["data"];
  Text: ComponentProps<typeof Text>["data"];
  TextDeck: ComponentProps<typeof TextDeck>["data"];
  TextDeckSelect: ComponentProps<typeof TextDeckSelect>["data"];
};

export type CellLayout = keyof typeof Cells | string;

export default Cells as {
  [key: CellLayout]: (...props: unknown[]) => JSX.Element;
};
