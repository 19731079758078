import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Label } from "~/components/molecules";
import type { ThemeType } from "@sablier/v2-themes";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: center;
    height: 100%;
    & > * {
      min-height: 18px;
    }
  }
`;

const Content = styled.div<{ color?: keyof ThemeType["colors"] }>`
  ${(props) => props.theme.styles.row}

  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `}

  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

export interface Props {
  data: ITableCell & {
    value: {
      content: string;
      contentColor?: keyof ThemeType["colors"];
      label: ComponentProps<typeof Label> | string;
    };
  };
}

function TextDeckCell({ data }: Props) {
  const value = useMemo(() => data.value, [data]);

  const Top = useCallback(() => {
    const label = value.label;
    if (_.isString(label)) {
      return <Label value={label} />;
    }
    if (_.has(label, "value")) {
      return <Label {...(label as ComponentProps<typeof Label>)} />;
    }
    return <></>;
  }, [value]);

  return (
    <Wrapper>
      <Inner>
        <Top />
        <Content color={value.contentColor}>
          <p>{_.toString(_.get(value, "content"))}</p>
        </Content>
      </Inner>
    </Wrapper>
  );
}

export default TextDeckCell;
