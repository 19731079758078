import { useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ITableCell } from "@sablier/v2-types";

const Wrapper = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: center;
    height: 100%;
  }
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

export interface Props {
  data: ITableCell & {
    value: string;
  };
}

function TextCell({ data }: Props) {
  const value = useMemo(() => data.value, [data]);

  return (
    <Wrapper>
      <Inner>
        <p>{_.toString(value)}</p>
      </Inner>
    </Wrapper>
  );
}

export default TextCell;
