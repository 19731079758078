import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Label } from "~/components/molecules";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: center;
    height: 100%;
  }
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

export interface Props {
  data: ITableCell & {
    value: ComponentProps<typeof Label> | string;
  };
}

function LabelCell({ data }: Props) {
  const value = useMemo(() => data.value, [data]);

  const Value = useCallback(() => {
    if (_.isString(value)) {
      return <Label value={value} />;
    }
    if (_.has(value, "value")) {
      return <Label {...(value as ComponentProps<typeof Label>)} />;
    }
    return <></>;
  }, [value]);

  return (
    <Wrapper>
      <Inner>
        <Value />
      </Inner>
    </Wrapper>
  );
}

export default LabelCell;
