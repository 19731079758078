import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";

export default function useShortAddressOrName(
  value: string | undefined,
  a = 6,
  b = -3,
) {
  return useMemo(() => _.toShortAddressOrName(value, a, b), [value, a, b]);
}
