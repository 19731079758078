import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useTableAccessors } from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import { Icon, Label } from "~/components/molecules";
import type { ThemeType } from "@sablier/v2-themes";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps, MouseEvent } from "react";

const WrapperPartial = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  cursor: pointer !important;
  p,
  span,
  label {
    cursor: pointer !important;
  }
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: center;
    height: 100%;
    & > * {
      min-height: 18px;
    }
  }
`;

const Content = styled.div<{ color?: keyof ThemeType["colors"] }>`
  ${(props) => props.theme.styles.row}
  & {
    gap: 5px;

    & > p {
      ${(props) => props.theme.styles.textElement}
    }
  }

  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `}
`;

const Checkbox = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 12px;
    --thickness: 4;
    flex-shrink: 0;
    justify-content: center;
    width: 18px;
    height: 18px;

    color: ${(props) => props.theme.colors.transparent};
    border: 2px solid ${(props) => props.theme.colors.gray500};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark100};
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Content} {
    --table-row-state: max(
      var(--table-row-selected, 0),
      var(--table-row-hover, 0)
    );

    transform: translateX(calc(22px * (var(--table-row-state, 0) - 1)));
    will-change: transform;
    transition: transform ease-in 100ms 50ms;
    ${Checkbox} {
      opacity: var(--table-row-state, 0);
      will-change: opacity;
      transition: opacity ease-in 150ms;
    }
  }

  &:not([data-selected="true"]) {
    &:hover,
    &:active {
      ${Content} {
        ${Checkbox} {
          background-color: ${(props) => props.theme.colors.dark500};
        }
      }
    }
  }

  &[data-selected="true"] {
    ${Checkbox} {
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.secondaryDesaturated};
      background-color: ${(props) => props.theme.colors.secondaryDesaturated};
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    width: 100%;
    max-width: 100%;
    pointer-events: all;
    ${Content} {
      transform: translate(0);
      ${Checkbox} {
        opacity: 1;
      }
    }
  }
`;

export interface Props {
  data: ITableCell & {
    value: {
      content: string;
      contentColor?: keyof ThemeType["colors"];
      label: ComponentProps<typeof Label> | string;
    };
  };

  x: number;
  y: number;
}

function TextDeckSelectCell({ data, x }: Props) {
  const value = useMemo(() => data.value, [data]);

  const accessors = useTableAccessors();
  const { getRow, getInstruction } = accessors;
  const { id, isSelected } = useMemo(() => getRow({ x }), [getRow, x]);

  const onSelect = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const onRowSelect = getInstruction({ name: "onRowSelect" });
      if (_.isFunction(onRowSelect)) {
        onRowSelect(id, event);
      }
    },
    [getInstruction, id],
  );

  const Top = useCallback(() => {
    const label = value.label;
    if (_.isString(label)) {
      return <Label value={label} />;
    }
    if (_.has(label, "value")) {
      return <Label {...(label as ComponentProps<typeof Label>)} />;
    }
    return <></>;
  }, [value]);

  return (
    <Wrapper data-selected={isSelected} onClick={onSelect}>
      <Inner>
        <Top />
        <Content color={value.contentColor}>
          <Checkbox>
            <Icon value={CheckIcon} />
          </Checkbox>
          <p>{_.toString(_.get(value, "content"))}</p>
        </Content>
      </Inner>
    </Wrapper>
  );
}

export default TextDeckSelectCell;
