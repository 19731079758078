import { useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Amount } from "~/components/molecules";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: center;
    height: 100%;
  }
`;

export interface Props {
  data: ITableCell & {
    value: ComponentProps<typeof Amount>;
  };
}

function AmountCell({ data }: Props) {
  const amount = useMemo(() => data.value, [data]);

  return (
    <Wrapper>
      <Inner>
        <Amount {...amount} />
      </Inner>
    </Wrapper>
  );
}

export default AmountCell;
