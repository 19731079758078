import { useMemo } from "react";
import styled from "styled-components";
import { ProgressBox as ProgressBoxPartial } from "~/components/molecules";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    grid-column: auto;
    padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

const ProgressBox = styled(ProgressBoxPartial)`
  height: 46px;
  & > * {
    max-width: 200px;
  }
`;

export interface Props {
  data: ITableCell & {
    value: ComponentProps<typeof ProgressBoxPartial>;
  };
}

function ProgressCell({ data }: Props) {
  const value = useMemo(() => data.value, [data]);

  return (
    <Wrapper>
      <ProgressBox {...value} />
    </Wrapper>
  );
}

export default ProgressCell;
