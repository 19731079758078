import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useTableAccessors, useTableElements } from "@sablier/v2-contexts";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Button } from "~/components/molecules";
import type { MouseEvent } from "react";

export const Wrapper = styled.div<{ template?: string }>`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    :empty {
      display: none;
    }
  }
`;

function Footer() {
  const accessors = useTableAccessors();
  const { isLoading, isEmpty } = useTableElements();
  const { t } = useT();

  const { getInstruction } = accessors;

  const isMore = useMemo(
    () => !!getInstruction({ name: "onMore" }) && !isEmpty,
    [getInstruction, isEmpty],
  );

  const onMore = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const onMore = getInstruction({ name: "onMore" });
      if (_.isFunction(onMore)) {
        onMore(event);
      }
    },
    [getInstruction],
  );

  return useMemo(
    () => (
      <Wrapper data-component={"footer"}>
        {isMore && (
          <Button
            accent={"iconic"}
            appearance={"outline"}
            isLoading={isLoading}
            isMini={true}
            isPreview={isLoading}
            onClick={onMore}
            title={t("structs.showMore")}
          />
        )}
      </Wrapper>
    ),
    [isMore, isLoading, onMore, t],
  );
}

export default Footer;
