import { CSSProperties, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useTableAccessors, useTableElements } from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import type { MouseEvent, PropsWithChildren } from "react";
import Cells from "../Cells";

export const Wrapper = styled.div<{ template?: string }>`
  --table-row-hover: 0;
  --table-row-selected: ;
  position: relative;
  display: grid;
  grid-template-columns: ${(props) => props.template || "auto"};
  grid-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  width: 100%;
  height: calc(var(--table-option-row) * 1px);
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 1);
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.dark150};

  &:hover,
  &:active {
    --table-row-hover: 1;
    background-color: ${(props) => props.theme.colors.dark200};
  }

  &[data-animated="true"] {
    ${(props) => props.theme.animations.fadeIn}
    & {
      animation-duration: 100ms;
    }
  }

  &:not(:last-child) {
    border-bottom: 2px solid ${(props) => props.theme.colors.border};
  }

  &[data-selected="true"] {
    --table-row-selected: 1;
    background-color: ${(props) => props.theme.colors.dark200};
    &:hover,
    &:active {
      --table-row-hover: 1;
      background-color: ${(props) => props.theme.colors.dark300};
    }
    &:not(:last-child) {
      border-bottom: 2px solid ${(props) => props.theme.colors.dark400};
    }
  }
`;

interface Props {
  x: number;
  style?: CSSProperties;
}

function Row({ x, style }: PropsWithChildren<Props>) {
  const accessors = useTableAccessors();
  const { template, isAnimated } = useTableElements();

  const { getColumn, getRow, getInstruction } = accessors;
  const { cells, id, isSelected } = useMemo(() => getRow({ x }), [getRow, x]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const onRowClick = getInstruction({ name: "onRowClick" });
      if (_.isFunction(onRowClick)) {
        onRowClick(id, event);
      }
    },
    [getInstruction, id],
  );

  return useMemo(
    () => (
      <Wrapper
        data-animated={isAnimated}
        data-component={"row"}
        data-selected={isSelected}
        data-id={id}
        onClick={onClick}
        template={template}
        style={style}
      >
        {_.toArray(cells).map((cell, y) => {
          try {
            const column = getColumn({ y });
            const cellId = `${id}-${column.id}`;
            const layout = _.get(column, "layout") || "Text";
            const Component = Cells[layout];

            const props = { data: cell, x, y };

            if (!_.isNil(Component)) {
              return <Component key={cellId} {...props} />;
            }
            return <Cells.Text key={cellId} {...props} />;
          } catch (error) {
            vendors.crash.log(error);
          }
          return null;
        })}
      </Wrapper>
    ),
    [cells, getColumn, id, isAnimated, onClick, template, style, isSelected, x],
  );
}

export default Row;
